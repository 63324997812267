import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import moment from 'moment';
import { getSuffix } from '../SimpleCardsGroup/utils';
import { Spin } from 'antd';
import { post } from 'common/utils';
import CommonContext from '../../context';

const renderChart = (refNode, datas = []) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };

        const option = {
            tooltip: {
                trigger: 'axis',
            },
            grid: {
                left: 3,
                top: 11,
                right: 3,
                bottom: 1,
            },
            xAxis: {
                type: 'time',
                show: false,
            },
            yAxis: {
                type: 'value',
                scale: true,// true时不一定显示0
                show: false,
            },
            series: [
                {
                    data: (datas)
                        .filter(([_, v]) => v !== null)
                        .map(([time, v]) => [moment(time).unix() * 1000, Math.floor(v * 1000) / 1000]),
                    type: 'line',
                    itemStyle: {
                        color: '#00676B',
                    },
                    symbol: 'none',
                    lineStyle: {
                        width: 1,
                    },
                },
            ],
        };

        instance.setOption(option);
    };
};

// 日：看过去两周的日数据趋势
// 周：过去10周的周数据
// 月：过去12个月的月数据

const Comp = (props) => {
    // const { } = props;
    const chartNode = useRef(null);
    const info = useRef(props.info);
    const [loading, setLoading] = useState(false);
    const commonInfo = useContext(CommonContext);
    const [value, setValue] = useState(null);
    const [rate, setRate] = useState(null);
    useEffect(() => {
        if (!!commonInfo) {
            const { state: { selectedDevice, time } } = commonInfo;
            if (!selectedDevice || !time) return;
            const [timeTab, timeStr] = time;
            const [curr_tag_1, curr_tag_2, his_tag] = ['a', 'b', 'chart'].map((key) => selectedDevice.tag_name + info.current.extd_tag + getSuffix(key, timeTab.tag, timeStr));
            const delta = timeTab.simpleCard_delta;

            let create_time = moment(timeStr).endOf(timeTab.momentUnit).add(1, 'days').format("YYYY-MM-DD 06:30:00");

            // 历史create_time取周期末的23:30:00
            // 当前周期的create_time取当前时间前1小时10分
            // let create_time = moment(timeStr).endOf(timeTab.momentUnit).format("YYYY-MM-DD 23:30:00");
            // if (moment(timeStr).isSame(moment(), timeTab.isAfterKey)) {
            //     // 当前周期内
            //     create_time = moment().subtract(1, 'hours').format("YYYY-MM-DD HH:10:00");
            // };

            setLoading(true);
            Promise.all([
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                    tag_name_list: [curr_tag_1, curr_tag_2],
                    create_time,
                }),
                post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
                    tag_list: [his_tag]
                        .map((tag_name) => {
                            return {
                                tag_name,
                                time_begin: moment(timeStr).subtract(delta[0], delta[1]).startOf(timeTab.momentUnit).format("YYYY-MM-DD 07:00:00"),
                                time_end: moment(timeStr).endOf(timeTab.momentUnit).add(1, 'days').format("YYYY-MM-DD 07:00:00"),
                            };
                        }),
                }),

            ])
                .then(([retData1, retData2]) => {
                    setLoading(false);
                    if (!!retData1.data && retData1.data.errcode === 0) {
                        const tag2Value = retData1.data.results;
                        let nextValue = null;
                        if (tag2Value[curr_tag_1] !== undefined) {
                            nextValue = tag2Value[curr_tag_1];
                        };
                        let nextRate = null;
                        if (tag2Value[curr_tag_2] !== undefined) {
                            nextRate = tag2Value[curr_tag_2];
                        };
                        setValue(nextValue);
                        setRate(nextRate);
                    };
                    if (!!retData2.data && retData2.data.errcode === 0) {
                        if (retData2.data.results.length > 0) {
                            renderChart(chartNode.current, retData2.data.results[0].data_list);
                        };
                    };
                });
        };
    }, [commonInfo]);
    const { name, unit } = info.current;
    const valueText = useMemo(() => {
        if (typeof value === 'number') {
            return value >= 1000 ? Math.floor(value) : (value >= 100 ? Math.floor(value * 10) / 10 : (value >= 10 ? Math.floor(value * 100) / 100 : (Math.floor(value * 1000) / 1000)));
        } else {
            return '--';
        };
    }, [value]);
    const rateText = useMemo(() => {
        if (typeof rate === 'number') {
            return Math.floor(rate * 100) / 100;
        } else {
            return '--';
        };
    }, [rate]);
    return (
        <div className={style['wrapper']}>
            <div className="title_row">
                <div className="title">{name}</div>
                {/* <div className="arrow down_arrow" /> */}
                <div className="percent_text">{rateText}%</div>
            </div>
            <div className="value_row">
                <div className="value">{valueText}</div>
                <div className="unit">{unit}</div>
            </div>
            <div className="chart_block">
                <div style={{ height: 35 }} ref={chartNode} />
            </div>
            {
                loading && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin tip="加载中..." />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
