import React from 'react';
import { get, post } from '../../common/utils.js';
import DeviceDynamicPic from './DeviceDynamicPic.js';
import style from './index.module.scss';
import { withRouter } from 'react-router';
import moment from 'moment';

let DEVICE_LIST = [];
let baseUrl = process.env.REACT_APP_SERVER_PATH;
if (["xinchen"].includes(process.env.REACT_APP_PROJECT_TAG)) {
    baseUrl = "https://yxsn.quantile.tech/";
};

const INTERVAL_SECONDS = 10

class DiagramDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // diagramId: null,
            diagramData: {
                HWRatio: null,
                fontData: []
            },
            dataList: {},
            diagramConf: {},
            subTitle: null,
            selectedKeys: [],
        }
        this.tag_name_list = null
        this.playInfo = null
    }
    componentWillUnmount() {
        clearInterval(this.state.setIntervalId);
    }
    componentDidMount() {
        clearInterval(this.state.setIntervalId);
        let deviceId = null;
        try {
            deviceId = this.props.match.params.id;
        } catch (err) { };
        if (typeof deviceId !== 'string') {
            const { deviceId: dId } = this.props;
            if (typeof dId === 'string') {
                deviceId = dId;
            };
        };
        if (typeof deviceId === 'string') {
            this.setNewDiagram(deviceId);
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps?.isPause !== this.props?.isPause) {
            this.handlePause()
        }
        if (prevProps?.playInfo !== this.props?.playInfo) {
            this.handlePlayInfo()
        }
    }
    handlePlayInfo = () => {
        if (this.props?.playInfo?.type === 'HISTORY') {
            clearInterval(this.state.setIntervalId);
            this.playInfo = Object.assign({}, this.props.playInfo)
            this.getLatestData()
            if (!this.props.isPause) {
                const setIntervalId = setInterval(this.getLatestData, INTERVAL_SECONDS * 1000);
                this.setState({ setIntervalId });
            }
        } else {
            // REALTIME
            if (this.playInfo?.type === 'HISTORY') {
                clearInterval(this.state.setIntervalId);
                this.playInfo = null
                this.getLatestData()
                if (!this.props.isPause) {
                    const setIntervalId = setInterval(this.getLatestData, INTERVAL_SECONDS * 1000);
                    this.setState({ setIntervalId });
                }
            }
        }
    }
    handlePause = () => {
        clearInterval(this.state.setIntervalId);
        if (this.props.isPause) {
            // 
        } else {
            this.getLatestData()
            const setIntervalId = setInterval(this.getLatestData, INTERVAL_SECONDS * 1000);
            this.setState({ setIntervalId });
        }
    }
    setNewDiagram = deviceId => {
        let self = this, diagramData = self.state.diagramData, tag_alias_list = [];
        get(process.env.REACT_APP_SERVER_PATH + 'api/diagram/get_by_uuid/' + deviceId).then(retData => {
            // console.log(retData);
            if (!!retData.data && retData.data.errcode === 0) {
                let newData = retData.data.result;
                if (!newData || newData.content === null) return;
                try {
                    let fontData = this.generateData(JSON.parse(newData.content)), HWRatio = 1;
                    if (fontData.length > 0 && fontData[0].bckSize) {
                        let bckSize = fontData[0].bckSize;
                        HWRatio = bckSize.height / bckSize.width;
                    }
                    diagramData = {
                        fontData,
                        HWRatio
                    };
                    fontData.forEach(item => {
                        if (item.tag_alias && item.tag_alias !== 'bckalias') tag_alias_list.push(item.tag_alias);
                    })
                    this.tag_name_list = tag_alias_list

                    self.getLatestData();
                    let setIntervalId = setInterval(() => { self.getLatestData() }, INTERVAL_SECONDS * 1000);

                    self.setState({ setIntervalId });
                } catch (error) {
                    console.log("error json");
                }
                self.setState({ diagramData, diagramConf: newData });
            } else {
                self.setState({
                    diagramData: {
                        HWRatio: null,
                        fontData: []
                    }
                })
            }
        })
    }
    generateData = source => {
        // console.log("generate data");
        let genData = [];
        genData.push({
            type: 1,
            tag_alias: "bckalias",
            value: 200,
            conditionType: 3,
            condition: "",
            coordinate: [0, 0],
            width: 100,
            srcImg: [source.bckPic, ''],
            bckSize: source.bckSize
        });
        source.listData.forEach(item => {
            item.details.forEach(detail => {
                genData.push(Object.assign(
                    {},
                    {
                        type: item.type,
                        tag_alias: item.dataPoint,
                        coordinate: [detail.top, detail.right]
                    },
                    typeof detail.precision === 'number' ? { precision: detail.precision } : {}
                ));
                if ([1, 2].includes(item.type)) {
                    genData[genData.length - 1].width = detail.size;
                    genData[genData.length - 1].srcImg = [detail.img, ''];
                    if (item.type === 1) {
                        genData[genData.length - 1].conditionType = detail.type;
                        genData[genData.length - 1].condition = detail.type === 3 ? "" : detail.condition;
                    } else {
                        genData[genData.length - 1].condition = detail.condition;
                    }
                } else if ([0, 3, 4].includes(item.type)) {
                    genData[genData.length - 1].fontSize = detail.size * 100 / source.bckSize.width;
                    genData[genData.length - 1].lineHeight = detail.lineHeight ? detail.lineHeight : 100;
                    genData[genData.length - 1].color = detail.color ? detail.color : '#000000';
                    if (item.type === 4) {
                        genData[genData.length - 1].text = detail.text;
                    }
                    if (item.type === 0) {
                        genData[genData.length - 1].need_chart = item.need_chart ?? false;
                    }
                }
            });
        });
        return genData;
    }
    getLatestData = () => {
        // if (tag_alias_list == "") return;
        const tag_name_list = this.tag_name_list
        let self = this;
        const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
        let default_time = (typeof env_time === 'string' && env_time !== "")
            ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
            : moment().format("YYYY-MM-DD HH:mm:ss");
        if (this.playInfo?.type === 'HISTORY') {
            const hisTime = moment(this.playInfo.time).format("YYYY-MM-DD HH:mm:ss");
            // 判断hisTime是否大于当前时刻
            if (moment(hisTime).isSameOrBefore(moment())) {
                default_time = hisTime
                const cb = this.props?.onHisTimeChange ?? (() => { })
                cb(default_time)
                this.playInfo = Object.assign(this.playInfo, {
                    time: moment(this.playInfo.time).add(INTERVAL_SECONDS, 'seconds')
                })
            } else {
                default_time = moment().format("YYYY-MM-DD HH:mm:ss")
            }
        }
        post(baseUrl + "api/data/get_values_before_time_x", {
            create_time: default_time,
            tag_name_list,
        }).then((retData) => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                let nextDataList = retData.data.results;
                nextDataList = Object.keys(nextDataList)
                    .reduce((pV, cKey) => {
                        return Object.assign({}, pV, {
                            [cKey]: nextDataList[cKey],
                        });
                    }, {});
                if (process.env.REACT_APP_PROJECT_TAG === 'jinglei' && typeof nextDataList['JL.BSY.YZS'] === 'number') {
                    nextDataList['JL.BSY.YZS'] = nextDataList['JL.BSY.YZS'] * 2.2 / 50
                }
                self.setState({ dataList: nextDataList });
            }
        });
    }
    deviceRouter = (deviceId) => {
        console.log(deviceId);
        let i, deviceGroupId = this.state.selectedKeys[0].split('-')[0], deviceList;
        for (i = 0; i < DEVICE_LIST.length; i++) {
            if (deviceGroupId + '' === DEVICE_LIST[i].value + '') break;
        }
        if (i === DEVICE_LIST.length) {
            alert("跳转设备不存在：id=" + deviceId);
            return;
        }
        deviceList = DEVICE_LIST[i].children
        for (i = 0; i < deviceList.length; i++) {
            if (deviceId + '' === deviceList[i].value.split('-')[1]) break;
        }
        if (i === deviceList.length) {
            alert("跳转设备不存在：id=" + deviceId);
            return;
        }
        this.setState({ subTitle: deviceList[i].label, selectedKeys: [deviceList[i].value.split('-')[0] + '-' + deviceId] })
        clearInterval(this.state.setIntervalId);
        this.setNewDiagram(deviceId);
    }
    render() {
        let self = this;

        return (
            <div className={style["DiagramDisplayWrapper"]}>
                <DeviceDynamicPic diagramData={self.state.diagramData} dataList={self.state.dataList} diagramConf={self.state.diagramConf} deviceRouter={self.deviceRouter} />
            </div>
        );
    }
}

export default withRouter(DiagramDisplay);
