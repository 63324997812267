import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import style from './index.module.scss';
import PIC_1 from './res/pic.svg';
import Card from '../SingleCard';
import CommonContext from '../../context';
import { post } from 'common/utils';
import ICON_POWER from '../InfoCard/res/power.png'

const cards = [
    {
        name: '有功功率',
        unit: 'kW',
        abnormal: false,
        highlight: false,
        valueColor: '#355B99',
        extd_tag: '_kWz',
    },
    {
        name: '无功功率',
        unit: 'kVar',
        abnormal: false,
        highlight: false,
        valueColor: '#355B99',
        extd_tag: '_kWRz',
    },
    {
        name: '视在功率',
        unit: 'kVA',
        abnormal: false,
        highlight: false,
        valueColor: '#355B99',
        extd_tag: '_kVAz',
    },
];

const barInfos = [
    {
        name: '总',
        color: '#355B99',
        extd_tag: '_PFz',
    },
    {
        name: 'A相',
        extd_tag: '_PFa',
    },
    {
        name: 'B相',
        extd_tag: '_PFb',
    },
    {
        name: 'C相',
        extd_tag: '_PFc',
    },
];

const allExtdTags = [...new Set(cards.concat(barInfos).map(({ extd_tag }) => extd_tag))];

const getAllTags = (basic_tag) => {
    return allExtdTags.map((tag) => basic_tag + tag);
};

const barStandardPercent = 90;
const titleWidth = 32;
const valueWidth = 50;

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const [tag2Value, setTag2Value] = useState({});
    const [selectedDevice, refreshTick] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice, commonInfo.state.refreshTick];
        };
        return [null, null];
    }, [commonInfo]);
    const queryLock = useRef(null);
    useEffect(() => {
        if (!!selectedDevice) {
            const currLock = (queryLock.current = Symbol());
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                tag_name_list: getAllTags(selectedDevice.tag_name),
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
            return () => {
                queryLock.current = Symbol();
            };
        };
    }, [selectedDevice, refreshTick]);
    const getValueByExtdTag = useCallback((extd_tag) => {
        if (!!selectedDevice) {
            if (typeof extd_tag === 'string') {
                const value = tag2Value[selectedDevice.tag_name + extd_tag];
                if (typeof value === 'number') {
                    return value;
                };
            };
        };
        return null;
    }, [tag2Value, selectedDevice]);
    return (
        <div className={style['wrapper']}>
            <div className="head">
                <img src={ICON_POWER} />
                <div className="text">功率</div>
            </div>
            <div className="content">
                <div className="left">
                    <div className="content">
                        <div className="title_wrapper">
                            <div className="text">功率</div>
                        </div>
                        <div className="cards_wrapper">
                            {
                                cards
                                    .map((item, index) => {
                                        const { extd_tag } = item;
                                        const value = getValueByExtdTag(extd_tag);
                                        return (
                                            <Card key={index} {...item} value={value} precision={1} />
                                        );
                                    })
                            }
                            <img alt="" src={PIC_1} width="152" height="88" />
                        </div>
                    </div>
                </div>
                <div className="line"></div>
                <div className="right">
                    <div className="title">功率因数</div>
                    <div className="content">
                        {
                            barInfos
                                .map((item, index) => {
                                    const { name, extd_tag, color } = item;
                                    let value = getValueByExtdTag(extd_tag);
                                    let valueText = '--';
                                    let barWidth = 0;
                                    let colorRed = false;
                                    if (typeof value === 'number') {
                                        value = Math.abs(value);
                                        valueText = Math.floor(value * 100) / 100;
                                        barWidth = value * 100;
                                        colorRed = value * 100 < barStandardPercent;
                                    };
                                    return (
                                        <div className="row_wrapper" key={index}>
                                            <div className="title" style={{ width: titleWidth }}>{name}</div>
                                            <div className="bar_wrapper">
                                                <div className="colored_bar" style={Object.assign({ width: `${barWidth}%` }, typeof color === 'string' ? { backgroundColor: color } : {})}></div>
                                            </div>
                                            <div className={"value" + (colorRed ? " value_red" : "")} style={{ width: valueWidth }}>{valueText}</div>
                                        </div>
                                    );
                                })
                        }
                        <div style={{ position: 'absolute', left: titleWidth, right: valueWidth, top: -8, bottom: -8 }}>
                            <div style={{ position: 'absolute', left: `${barStandardPercent}%`, width: 0, height: '100%', top: 0, borderRight: '1px dashed #C9C9C9' }}>
                                <div className="standard_value_text" style={{ position: 'absolute', bottom: -10, left: '50%', transform: 'translate(-50%,100%)' }}>{barStandardPercent / 100}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
