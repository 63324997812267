import { useEffect, useMemo, useState } from 'react';
import Chart from './index.js';
import moment from 'moment';
import { post } from 'common/utils.js';

const Comp = (props) => {
    const { config, range, id } = props;
    const [lineData, setLineData] = useState(null);
    const { meta_list } = useMemo(() => {
        const meta_list = config?.meta_list ?? null;
        return {
            meta_list,
        };
    }, [config]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // console.log(tag_name, range);
        const tags = (meta_list ?? [])
            .map(({ tag_name }) => tag_name);
        if (tags.length > 0 && !!range) {
            const time_end = moment(range[1]);
            setLoading(true);
            let delay = true;
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                create_time: moment(time_end).format('YYYY-MM-DD HH:mm:ss'),
                tag_name_list: tags,
            })
                .then(retData => {
                    if (!delay) {
                        return;
                    };
                    setLoading(false);
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        setLineData(retData?.data?.results ?? null)
                    };
                });
            return () => {
                delay = false;
            };
        } else {
            setLineData(null);
        };
    }, [meta_list, range, id]);
    return (
        <Chart
            config={config}
            data={lineData}
            loading={loading}
            meta_list={meta_list}
        />
    );
};

export default Comp;
