import React, { useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import {
    CommonObj,
} from '../utils';
import { group_G, group_H } from './constans';
import Card3 from '../components/Card_3';
import { post } from 'common/utils';

const Comp = (props) => {
    const { refreshTick } = props;
    const currScene = useRef(props.sceneInfo);
    const onLoadOver = useRef(props.onLoadOver);
    const [gpGIndex, setgpGIndex] = useState(null);
    const [gpHIndex, setgpHIndex] = useState(null);
    const [tag2Value, setTag2Value] = useState({});

    const queryLock = useRef(null);
    useEffect(() => {
        if (typeof refreshTick === 'symbol') {
            // refresh signal
            const tag_name_list = []
                .concat(
                    ...(
                        group_G.children.map((child) => {
                            const { basic_tag, tag_1, tag_2 } = child;
                            return [tag_1, tag_2].map((tag) => basic_tag + tag);
                        })
                    ),
                    ...(
                        group_H.children.map((child) => {
                            const { basic_tag, tag_1, tag_2 } = child;
                            return [tag_1, tag_2].map((tag) => basic_tag + tag);
                        })
                    ),
                );
            const trimed_tag_name_list = [...new Set(tag_name_list)];
            const currLock = Symbol();
            queryLock.current = currLock;
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                tag_name_list: trimed_tag_name_list,
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
        };
        return () => {
            queryLock.current = Symbol();
        };
    }, [refreshTick]);

    useEffect(() => {
        onLoadOver.current = props.onLoadOver;
    }, [props]);
    useEffect(() => {
        if (!currScene.current) return;
        currScene.current.displayFunc(
            {//Action-2
                num: group_G.children.length,
                cb: (index) => {
                    // console.log(`now G display no.${index}`);
                    setgpGIndex(index);
                },
            },
            {//Action-3
                num: group_H.children.length,
                cb: (index) => {
                    // console.log(`now H display no.${index}`);
                    setgpHIndex(index);
                },
            },
            {//Action-4
                cb: () => {
                    // console.log("G,H消失");
                    setgpGIndex(null);
                    setgpHIndex(null);
                },
            },
            {//over
                cb: () => {
                    // console.log("over");
                    if (typeof onLoadOver.current === 'function') {
                        onLoadOver.current();
                    };
                },
            },
        );
        return () => {
            clearTimeout(CommonObj.mySetTimeout);
        };
    }, []);
    return (
        <div className={style['wrapper']}>
            {
                typeof gpGIndex === 'number' && (
                    <div className="gp_wrapper gpG">
                        <div className="title">{group_G.name}</div>
                        <div className="content">
                            {
                                group_G.children
                                    .map((item, index) => {
                                        const { basic_tag, tag_1, tag_2, precision_1 } = item;
                                        const values = [tag_1, tag_2].map((tag) => tag2Value[basic_tag + tag]);
                                        const precisions = [precision_1, null].map((v) => typeof v === 'number' ? v : null);
                                        return (
                                            <div style={{ marginTop: index > 0 ? 8 : 0 }} key={index} className={index > gpGIndex ? "card_hide" : ""}>
                                                <Card3 name={item.name} unit={item.unit} values={values} precisions={precisions} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                )
            }
            {
                typeof gpHIndex === 'number' && (
                    <div className="gp_wrapper gpH">
                        <div className="title">{group_H.name}</div>
                        <div className="content">
                            {
                                group_H.children
                                    .map((item, index) => {
                                        const { basic_tag, tag_1, tag_2, precision_1 } = item;
                                        const values = [tag_1, tag_2].map((tag) => tag2Value[basic_tag + tag]);
                                        const precisions = [precision_1, null].map((v) => typeof v === 'number' ? v : null);
                                        return (
                                            <div style={{ marginTop: index > 0 ? 8 : 0 }} key={index} className={index > gpHIndex ? "card_hide" : ""}>
                                                <Card3 name={item.name} unit={item.unit} values={values} precisions={precisions} />
                                            </div>
                                        );
                                    })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Comp
