import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import ChartItem from './ChartItem';
import CommonContext from '../../context';
import { post } from 'common/utils';
import ICON from '../InfoCard/res/aberrate.png';

const datas = [
    {
        name: '电压',
        // extd_tag: '_thdVz',
        children: [
            {
                name: 'A相',
                extd_tag: '_thdVab',
            },
            {
                name: 'B相',
                extd_tag: '_thdVbc',
            },
            {
                name: 'C相',
                extd_tag: '_thdVca',
            },
        ],
    },
    {
        name: '电流',
        // extd_tag: '_thdIz',
        children: [
            {
                name: 'A相',
                extd_tag: '_thdIa',
            },
            {
                name: 'B相',
                extd_tag: '_thdIb',
            },
            {
                name: 'C相',
                extd_tag: '_thdIc',
            },
        ],
    },
];

const allExtdTags = [...new Set(
    datas
        .reduce((pV, cData) => {
            return pV.concat(cData.children.map(({ extd_tag }) => extd_tag));
        }, [])
)];

const getAllTags = (basic_tag) => {
    return allExtdTags.map((tag) => basic_tag + tag);
};

const Comp = (props) => {
    const commonInfo = useContext(CommonContext);
    const [tag2Value, setTag2Value] = useState({});
    const [selectedDevice, refreshTick] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice, commonInfo.state.refreshTick];
        };
        return [null, null];
    }, [commonInfo]);
    const queryLock = useRef(null);
    useEffect(() => {
        if (!!selectedDevice) {
            const currLock = (queryLock.current = Symbol());
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                tag_name_list: getAllTags(selectedDevice.tag_name),
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        setTag2Value(retData.data.results);
                    };
                });
            return () => {
                queryLock.current = Symbol();
            };
        };
    }, [selectedDevice, refreshTick]);
    const getValueByExtdTag = useCallback((extd_tag) => {
        if (!!selectedDevice) {
            if (typeof extd_tag === 'string') {
                const value = tag2Value[selectedDevice.tag_name + extd_tag];
                if (typeof value === 'number') {
                    return value;
                };
            };
        };
        return null;
    }, [tag2Value, selectedDevice]);
    const getAvgValueByTags = useCallback((extd_tags) => {
        const sum = extd_tags.reduce((pV, c_extd_tag) => {
            const c_value = getValueByExtdTag(c_extd_tag);
            if (typeof c_value !== 'number') {
                return null;
            };
            return pV + c_value;
        }, 0);
        if (typeof sum === 'number') {
            return sum / extd_tags.length;
        };
        return null;
    }, [getValueByExtdTag]);
    return (
        <div className={style['wrapper']}>
            <div className="head">
                {/*<img src={ICON} />*/}
                <div className="text">谐波畸变率</div>
            </div>
            <div className="content">
                {
                    datas
                        .map((item, index) => {
                            const { name, children } = item;
                            const allV = getAvgValueByTags(children.map(({ extd_tag }) => extd_tag));
                            return []
                                .concat(
                                    index > 0 ? [(
                                        <div className="line" key={`line_${index}`}></div>
                                    )] : [],
                                    [(
                                        <div className="item_wrapper" key={`item_${index}`}>
                                            <div className="title">{name}</div>
                                            <div style={{ marginTop: 20 }}></div>
                                            <ChartItem data={allV} />
                                            <div className="values_wrapper">
                                                {
                                                    children
                                                        .map((child, childIndex) => {
                                                            const { name, extd_tag } = child;
                                                            const value = getValueByExtdTag(extd_tag);
                                                            let valueText = '--';
                                                            if (typeof value === 'number') {
                                                                valueText = Math.floor(value * 100) / 100;
                                                            };
                                                            return (
                                                                <div className="v_wrapper" key={childIndex}>
                                                                    <div className="name">{name}</div>
                                                                    <div className="value_wrapper">
                                                                        <div className="value">{valueText}</div>
                                                                        <div className="unit">%</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    )]
                                );
                        })
                }
            </div>
        </div>
    );
};

export default Comp;
