import React, { Component } from 'react'
import { Modal } from 'antd'
// import { ExclamationCircleOutlined } from '@ant-design/icons'
import outImg from '../../assets/out_screen.png'
import './index.scss'
import YieldTarget from './YieldTarget'
import ProductionTarget from './ProductionTarget'
import EnergyTarget from './EnergyTarget'
import Overview from './Overview'
import ProductionTotal from './ProductionTotal'
import EnergyTotal from './EnergyTotal'
import DeviceStatus from './DeviceStaus'
import ProductionCost from './ProductionCost'
import { post } from '../../common/utils'
// import setScreen from '../../common/setScreen.js'
import moment from 'moment'
const { confirm } = Modal
class NewScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // 收率指标
      ethaneEfficiency: '--',
      ethaneEfficiencyChart: [],
      propaneEfficiency: '--',
      propaneEfficiencyChart: [],
      butaneEfficiency: '--',
      butaneEfficiencyChart: [],
      // 生产指标
      feedGasNumber: '--',
      feedGasPercent: '--',
      feedGasComparison: '--',
      feedGasChart: [],
      naturalGasNumber: '--',
      naturalGasPercent: '--',
      naturalGasComparison: '--',
      naturalGasChart: [],
      ethaneNumber: '--',
      ethanePercent: '--',
      ethaneComparison: '--',
      ethaneChart: [],
      propaneNumber: '--',
      propanePercent: '--',
      propaneComparison: '--',
      propaneChart: [],
      butaneNumber: '--',
      butanePercent: '--',
      butaneComparison: '--',
      butaneChart: [],
      olefinNumber: '--',
      olefinPercent: '--',
      olefinComparison: '--',
      olefinChart: [],
      // 能耗指标
      electricConsumeNumber: '--',
      electricConsumeChart: [],
      naturalGasConsumeNumber: '--',
      naturalGasConsumeChart: [],
      waterConsumeNumber: '--',
      waterConsumeChart: [],
      nitrogenConsumeNumber: '--',
      nitrogenConsumeChart: [],
      // 生产成本
      productionCostObj: {},
      // 设备状态
      deviceStatusObj: {},
      // 生产汇总
      gasProductWeek: '--',
      gasProductMonth: '--',
      gasProductYear: '--',
      ethaneProductWeek: '--',
      ethaneProductMonth: '--',
      ethaneProductYear: '--',
      propaneProductWeek: '--',
      propaneProductMonth: '--',
      propaneProductYear: '--',
      butaneProductWeek: '--',
      butaneProductMonth: '--',
      butaneProductYear: '--',
      olefinProductWeek: '--',
      olefinProductMonth: '--',
      olefinProductYear: '--',
      // 能耗汇总
      feedGasTotalWeek: '--',
      feedGasTotalMonth: '',
      feedGasTotalYear: '--',
      electricTotalWeek: '--',
      electricTotalMonth: '--',
      electricTotalYear: '--',
      waterTotalWeek: '--',
      waterTotalMonth: '--',
      waterTotalYear: '--',
      nitrogenTotalWeek: '--',
      nitrogenTotalMonth: '--',
      nitrogenTotalYear: '--',
      // 当前时间
      currentTime: moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getYieldTarget() {
    const tag_list = [
      'PLANT_C2H6_PRDT_RCRATE_H',
      'PLANT_C3H8_PRDT_RCRATE_H',
      'PLANT_C4H10_PRDT_RCRATE_H'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        this.setState({
          ethaneEfficiency: data.PLANT_C2H6_PRDT_RCRATE_H ? Number(data.PLANT_C2H6_PRDT_RCRATE_H * 100).toFixed(1) : '--',
          propaneEfficiency: data.PLANT_C3H8_PRDT_RCRATE_H ? Number(data.PLANT_C3H8_PRDT_RCRATE_H * 100).toFixed(1) : '--',
          butaneEfficiency: data.PLANT_C4H10_PRDT_RCRATE_H ? Number(data.PLANT_C4H10_PRDT_RCRATE_H * 100).toFixed(1) : '--'
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  getProductionTarget() {
    const tag_list = [
      'PLANT_RAWG_RAW_RATE_H',
      'PLANT_RAWG_RAW_RATE_HDQOQ',
      'PLANT_CNG_PRDT_RATE_H',
      'PLANT_CNG_PRDT_RATE_HDQOQ',
      'PLANT_C2H6_PRDT_RATE_H',
      'PLANT_C2H6_PRDT_RATE_HDQOQ',
      'PLANT_C3H8_PRDT_RATE_H',
      'PLANT_C3H8_PRDT_RATE_HDQOQ',
      'PLANT_C4H10_PRDT_RATE_H',
      'PLANT_C4H10_PRDT_RATE_HDQOQ',
      'PLANT_C5P_PRDT_RATE_H',
      'PLANT_C5P_PRDT_RATE_HDQOQ'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    }).then(res => {
      const data = res.data ? res.data.results : {}
      this.setState({
        feedGasNumber: data.PLANT_RAWG_RAW_RATE_H ? Number(data.PLANT_RAWG_RAW_RATE_H * 10000).toFixed(0) : '--',
        feedGasPercent: data.PLANT_RAWG_RAW_RATE_HDQOQ ? Number(data.PLANT_RAWG_RAW_RATE_HDQOQ).toFixed(2) : '--',
        naturalGasNumber: data.PLANT_CNG_PRDT_RATE_H ? Number(data.PLANT_CNG_PRDT_RATE_H * 10000).toFixed(0) : '--',
        naturalGasPercent: data.PLANT_CNG_PRDT_RATE_HDQOQ ? Number(data.PLANT_CNG_PRDT_RATE_HDQOQ).toFixed(2) : '--',
        ethaneNumber: data.PLANT_C2H6_PRDT_RATE_H ? Number(data.PLANT_C2H6_PRDT_RATE_H * 10000).toFixed(0) : '--',
        ethanePercent: data.PLANT_CNG_PRDT_RATE_HDQOQ ? Number(data.PLANT_CNG_PRDT_RATE_HDQOQ).toFixed(2) : '--',
        propaneNumber: data.PLANT_C3H8_PRDT_RATE_H ? Number(data.PLANT_C3H8_PRDT_RATE_H * 1000).toFixed(0) : '--',
        propanePercent: data.PLANT_C3H8_PRDT_RATE_HDQOQ ? Number(data.PLANT_C3H8_PRDT_RATE_HDQOQ).toFixed(2) : '--',
        butaneNumber: data.PLANT_C4H10_PRDT_RATE_H ? Number(data.PLANT_C4H10_PRDT_RATE_H * 1000).toFixed(0) : '--',
        butanePercent: data.PLANT_C4H10_PRDT_RATE_HDQOQ ? Number(data.PLANT_C4H10_PRDT_RATE_HDQOQ).toFixed(2) : '--',
        olefinNumber: data.PLANT_C5P_PRDT_RATE_H ? Number(data.PLANT_C5P_PRDT_RATE_H * 1000).toFixed(0) : '--',
        olefinPercent: data.PLANT_C5P_PRDT_RATE_HDQOQ ? Number(data.PLANT_C5P_PRDT_RATE_HDQOQ).toFixed(2) : '--'
      })
    })
  }

  /** 生产成本 */
  getProductionCost() {
    const tag_list = [
      'PLANT_RAWG_RAW_CHARCUM_HW',
      'PLANT_ALL_RAW_COSTRT_HW',
      'RAW_COSTRT_BCMK',
      'PLANT_ALL_ELEC_CHARCUM_HW',
      'PLANT_ALL_ELEC_COSTRT_HW',
      'ELEC_COSTRT_BCMK',
      // 'PLANT_OBLR_FUEL_CHARCUM_HW',
      'PLANT_FUEL_FG_CHARCUM_HW',
      'PLANT_ALL_FUEL_COSTRT_HW',
      'FUEL_COSTRT_BCMK',
      'PLANT_ALL_OTHER_CHARCUM_HW',
      'PLANT_ALL_OTHER_COSTRT_HW',
      'MISC_COSTRT_BCMK',
      'PLANT_ALL_CM_COSTRT_HW',
      'PLANT_C5P_PRDT_CHARCUM_HW',
      'PLANT_C4H10_PRDT_CHARCUM_HW',
      'PLANT_C3H8_PRDT_CHARCUM_HW',
      'PLANT_C2H6_PRDT_CHARCUM_HW',
      'PLANT_CNG_PRDT_CHARCUM_HW'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        this.setState({ productionCostObj: data })
      })
      .catch(err => {
        console.log('生产成本-数据加载失败', err)
      })
  }

  /** 设备状态 */
  getDeviceStatus() {
    const tag_list = [
      'COMP_TOT_EFF_RATE_H',
      'COMP_CASE1_EFF_RATE_H',
      'COMP_CASE2_EFF_RATE_H',
      'COMP_CASE34_EFF_RATE_H',
      'COMP_TOT_SHAFT_RATE_H',
      'COMP_CASE1_SHAFT_RATE_H',
      'COMP_CASE2_SHAFT_RATE_H',
      'COMP_CASE34_SHAFT_RATE_H',
      'COMP_TOT_HOUR_PER',
      'COMP_TOT_HOUR_CUM',
      'EXPC_EXP_EFF_RATE_H',
      'EXPC_EC_EFF_RATE_H',
      'EXPC_EXP_SHAFT_RATE_H'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        this.setState({ deviceStatusObj: data })
      })
      .catch(err => {
        console.log('设备状态-数据加载失败', err)
      })
  }
  getAllChart() {
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
      tag_list: [
        {
          tag_name: 'PLANT_C2H6_PRDT_RCRATE_D',
          time_begin: moment(this.state.currentDate)
            .subtract(9, 'day')
            .format('YYYY-MM-DD 23:30:00'),
          time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C3H8_PRDT_RCRATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C4H10_PRDT_RCRATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_RAWG_RAW_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_CNG_PRDT_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C2H6_PRDT_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C3H8_PRDT_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C4H10_PRDT_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_C5P_PRDT_RATE_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_ALL_ELEC_CONSUM_D',
          time_begin: moment(this.state.currentDate)
            .subtract(10, 'day')
            .format('YYYY-MM-DD HH:mm:ss'),
          time_end: this.state.currentTime
        },
        {
          tag_name: 'PLANT_FUEL_FG_CONSUM_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_COOL_H2O_CONSUM_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        },
        {
          tag_name: 'PLANT_N2GEN_N2_CONSUM_D',
            time_begin: moment(this.state.currentDate)
                .subtract(9, 'day')
                .format('YYYY-MM-DD 23:30:00'),
            time_end: moment().subtract(1,'hours').format('YYYY-MM-DD HH:10:00')
        }
      ]
    })
      .then(res => {
        const data = res.data ? res.data.results : []
        const chart_1 = []
        const chart_2 = []
        const chart_3 = []
        const chart_4 = []
        const chart_5 = []
        const chart_6 = []
        const chart_7 = []
        const chart_8 = []
        const chart_9 = []
        const chart_10 = []
        const chart_11 = []
        const chart_12 = []
        const chart_13 = []

        data.forEach(item => {
          if (item.tag_name === 'PLANT_C2H6_PRDT_RCRATE_D') {
            item.data_list.forEach(_item => {
              chart_1.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C3H8_PRDT_RCRATE_D') {
            item.data_list.forEach(_item => {
              chart_2.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C4H10_PRDT_RCRATE_D') {
            item.data_list.forEach(_item => {
              chart_3.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_RAWG_RAW_RATE_D') {
            item.data_list.forEach(_item => {
              chart_4.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_CNG_PRDT_RATE_D') {
            item.data_list.forEach(_item => {
              chart_5.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C2H6_PRDT_RATE_D') {
            item.data_list.forEach(_item => {
              chart_6.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C3H8_PRDT_RATE_D') {
            item.data_list.forEach(_item => {
              chart_7.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C4H10_PRDT_RATE_D') {
            item.data_list.forEach(_item => {
              chart_8.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_C5P_PRDT_RATE_D') {
            item.data_list.forEach(_item => {
              chart_9.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_ALL_ELEC_CONSUM_D') {
            item.data_list.forEach(_item => {
              chart_10.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_FUEL_FG_CONSUM_D') {
            item.data_list.forEach(_item => {
              chart_11.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_COOL_H2O_CONSUM_D') {
            item.data_list.forEach(_item => {
              chart_12.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
          if (item.tag_name === 'PLANT_N2GEN_N2_CONSUM_D') {
            item.data_list.forEach(_item => {
              chart_13.push({
                value: _item[1],
                itemStyle: {
                  color: '#33C2A4'
                }
              })
            })
          }
        })
        this.setColor(chart_1, 1)
        this.setColor(chart_2, 1)
        this.setColor(chart_3, 1)
        this.setColor(chart_4, 1)
        this.setColor(chart_5, 1)
        this.setColor(chart_6, 1)
        this.setColor(chart_7, 1)
        this.setColor(chart_8, 1)
        this.setColor(chart_9, 1)
        this.setColor(chart_10, 2)
        this.setColor(chart_11, 2)
        this.setColor(chart_12, 2)
        this.setColor(chart_13, 2)
        this.setState({
          ethaneEfficiencyChart: chart_1,
          propaneEfficiencyChart: chart_2,
          butaneEfficiencyChart: chart_3,
          feedGasChart: chart_4,
          naturalGasChart: chart_5,
          ethaneChart: chart_6,
          propaneChart: chart_7,
          butaneChart: chart_8,
          olefinChart: chart_9,
          electricConsumeChart: chart_10,
          naturalGasConsumeChart: chart_11,
          waterConsumeChart: chart_12,
          nitrogenConsumeChart: chart_13
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  // 根据条件判断
  setColor(arr, num) {
    if (arr.length < 1) {
        return
    }
    if (num === 1) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i].value >= arr[arr.length - 1].value) {
          arr[i].itemStyle.color = '#33C2A4'
        } else if (arr[i].value < arr[arr.length - 1].value) {
          arr[i].itemStyle.color = '#F16994'
        }
      }
    } else if (num === 2) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i].value >= arr[arr.length - 1].value) {
          arr[i].itemStyle.color = '#F16994'
        } else if (arr[i].value < arr[arr.length - 1].value) {
          arr[i].itemStyle.color = '#33C2A4'
        }
      }
    }
    arr[arr.length - 1].itemStyle.color = '#8DA1AF'
    return arr
  }
  getEnergyTarget() {
    const tag_list = [
      'PLANT_ALL_ELEC_CONSUM_H',
      'PLANT_FUEL_FG_CONSUM_HW',
      'PLANT_COOL_H2O_CONSUM_H',
      'PLANT_N2GEN_N2_CONSUM_H'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    }).then(res => {
      const data = res.data ? res.data.results : {}
      this.setState({
        electricConsumeNumber: data.PLANT_ALL_ELEC_CONSUM_H ? Number(data.PLANT_ALL_ELEC_CONSUM_H).toFixed(0) : '--',
        naturalGasConsumeNumber: data.PLANT_FUEL_FG_CONSUM_HW
          ? Number(data.PLANT_FUEL_FG_CONSUM_HW).toFixed(0)
          : '--',
        waterConsumeNumber: data.PLANT_COOL_H2O_CONSUM_H ? Number(data.PLANT_COOL_H2O_CONSUM_H).toFixed(0): '--',
        nitrogenConsumeNumber: data.PLANT_N2GEN_N2_CONSUM_H ? Number(data.PLANT_N2GEN_N2_CONSUM_H).toFixed(0) : '--'
      })
    })
  }
  getProductionTotal() {
    const tag_list = [
      'PLANT_CNG_PRDT_CUM_HW',
      'PLANT_CNG_PRDT_CUM_HM',
      'PLANT_CNG_PRDT_CUM_DY',
      'PLANT_C2H6_PRDT_CUM_HW',
      'PLANT_C2H6_PRDT_CUM_HM',
      'PLANT_C2H6_PRDT_CUM_DY',
      'PLANT_C3H8_PRDT_CUM_HW',
      'PLANT_C3H8_PRDT_CUM_HM',
      'PLANT_C3H8_PRDT_CUM_DY',
      'PLANT_C4H10_PRDT_CUM_HW',
      'PLANT_C4H10_PRDT_CUM_HM',
      'PLANT_C4H10_PRDT_CUM_DY',
      'PLANT_C5P_PRDT_CUM_HW',
      'PLANT_C5P_PRDT_CUM_HM',
      'PLANT_C5P_PRDT_CUM_DY'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    }).then(res => {
      const data = res.data ? res.data.results : {}
      this.setState({
        gasProductWeek: data.PLANT_CNG_PRDT_CUM_HW ? data.PLANT_CNG_PRDT_CUM_HW.toFixed(2) : '--',
        gasProductMonth: data.PLANT_CNG_PRDT_CUM_HM ? data.PLANT_CNG_PRDT_CUM_HM.toFixed(2) : '--',
        gasProductYear: data.PLANT_CNG_PRDT_CUM_DY ? data.PLANT_CNG_PRDT_CUM_DY.toFixed(1) : '--',
        ethaneProductWeek: data.PLANT_C2H6_PRDT_CUM_HW
          ? data.PLANT_C2H6_PRDT_CUM_HW.toFixed(2)
          : '--',
        ethaneProductMonth: data.PLANT_C2H6_PRDT_CUM_HM
          ? data.PLANT_C2H6_PRDT_CUM_HM.toFixed(2)
          : '--',
        ethaneProductYear: data.PLANT_C2H6_PRDT_CUM_DY
          ? data.PLANT_C2H6_PRDT_CUM_DY.toFixed(1)
          : '--',
        propaneProductWeek: data.PLANT_C3H8_PRDT_CUM_HW
          ? data.PLANT_C3H8_PRDT_CUM_HW.toFixed(2)
          : '--',
        propaneProductMonth: data.PLANT_C3H8_PRDT_CUM_HM
          ? data.PLANT_C3H8_PRDT_CUM_HM.toFixed(2)
          : '--',
        propaneProductYear: data.PLANT_C3H8_PRDT_CUM_DY
          ? data.PLANT_C3H8_PRDT_CUM_DY.toFixed(1)
          : '--',
        butaneProductWeek: data.PLANT_C4H10_PRDT_CUM_HW
          ? data.PLANT_C4H10_PRDT_CUM_HW.toFixed(2)
          : '--',
        butaneProductMonth: data.PLANT_C4H10_PRDT_CUM_HM
          ? data.PLANT_C4H10_PRDT_CUM_HM.toFixed(2)
          : '--',
        butaneProductYear: data.PLANT_C4H10_PRDT_CUM_DY
          ? data.PLANT_C4H10_PRDT_CUM_DY.toFixed(1)
          : '--',
        olefinProductWeek: data.PLANT_C5P_PRDT_CUM_HW
          ? data.PLANT_C5P_PRDT_CUM_HW.toFixed(2)
          : '--',
        olefinProductMonth: data.PLANT_C5P_PRDT_CUM_HM
          ? data.PLANT_C5P_PRDT_CUM_HM.toFixed(2)
          : '--',
        olefinProductYear: data.PLANT_C5P_PRDT_CUM_DY ? data.PLANT_C5P_PRDT_CUM_DY.toFixed(1) : '--'
      })
    })
  }
  getEnergyTotal() {
    let currentTime = ''
    const tag_list = [
      'PLANT_ALL_ELEC_CUM_HW',
      'PLANT_ALL_ELEC_CUM_HM',
      'PLANT_ALL_ELEC_CUM_DY',
      'PLANT_ALL_ELEC_CUM_HW',
      'PLANT_ALL_ELEC_CUM_HM',
      'PLANT_ALL_ELEC_CUM_DY',
      'PLANT_COOL_H2O_CUM_HW',
      'PLANT_COOL_H2O_CUM_HM',
      'PLANT_COOL_H2O_CUM_DY',
      'PLANT_N2GEN_N2_CUM_HW',
      'PLANT_N2GEN_N2_CUM_HW',
      'PLANT_N2GEN_N2_CUM_DY'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: this.state.currentTime,
      tag_name_list: tag_list
    }).then(res => {
      const data = res.data ? res.data.results : {}
      this.setState({
        feedGasTotalWeek: data.PLANT_ALL_ELEC_CUM_HW ? Number(data.PLANT_ALL_ELEC_CUM_HW).toFixed(3) : '--',
        feedGasTotalMonth: data.PLANT_ALL_ELEC_CUM_HM ? Number(data.PLANT_ALL_ELEC_CUM_HM).toFixed(2) : '',
        feedGasTotalYear: data.PLANT_ALL_ELEC_CUM_DY ? Number(data.PLANT_ALL_ELEC_CUM_DY).toFixed(1) : '--',
        electricTotalWeek: data.PLANT_ALL_ELEC_CUM_HW ? Number(data.PLANT_ALL_ELEC_CUM_HW).toFixed(3) : '--',
        electricTotalMonth: data.PLANT_ALL_ELEC_CUM_HM
          ? Number(data.PLANT_ALL_ELEC_CUM_HM).toFixed(2)
          : '--',
        electricTotalYear: data.PLANT_ALL_ELEC_CUM_DY
          ? Number(data.PLANT_ALL_ELEC_CUM_DY).toFixed(1)
          : '--',
        waterTotalWeek: data.PLANT_COOL_H2O_CUM_HW ? Number(data.PLANT_COOL_H2O_CUM_HW).toFixed(3) : '--',
        waterTotalMonth: data.PLANT_COOL_H2O_CUM_HM ? Number(data.PLANT_COOL_H2O_CUM_HM).toFixed(2) : '--',
        waterTotalYear: data.PLANT_COOL_H2O_CUM_DY ? Number(data.PLANT_COOL_H2O_CUM_DY).toFixed(1) : '--',
        nitrogenTotalWeek: data.PLANT_N2GEN_N2_CUM_HW ? Number(data.PLANT_N2GEN_N2_CUM_HW).toFixed(3) : '--',
        nitrogenTotalMonth: data.PLANT_N2GEN_N2_CUM_HM
          ? Number(data.PLANT_N2GEN_N2_CUM_HM).toFixed(2)
          : '--',
        nitrogenTotalYear: data.PLANT_N2GEN_N2_CUM_DY ? Number(data.PLANT_N2GEN_N2_CUM_DY).toFixed(1) : '--'
      })
    })
  }
  // 生产指标环比值昨天的今天的小时
  getProductionTargetBefore() {
    const tag_list = [
      'PLANT_RAWG_RAW_RATE_H',
      'PLANT_CNG_PRDT_RATE_H',
      'PLANT_C2H6_PRDT_RATE_H',
      'PLANT_C3H8_PRDT_RATE_H',
      'PLANT_C4H10_PRDT_RATE_H',
      'PLANT_C5P_PRDT_RATE_H'
    ]
    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
      create_time: moment(this.state.currentDate).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      tag_name_list: tag_list
    })
      .then(res => {
        const data = res.data ? res.data.results : {}
        this.setState({
          feedGasComparison: data.PLANT_RAWG_RAW_RATE_H
            ? Number(data.PLANT_RAWG_RAW_RATE_H * 10000).toFixed(0)
            : '--',
          naturalGasComparison: data.PLANT_CNG_PRDT_RATE_H
            ? Number(data.PLANT_CNG_PRDT_RATE_H * 10000).toFixed(0)
            : '--',
          ethaneComparison: data.PLANT_C2H6_PRDT_RATE_H
            ? Number(data.PLANT_C2H6_PRDT_RATE_H * 10000).toFixed(0)
            : '--',
          propaneComparison: data.PLANT_C3H8_PRDT_RATE_H
            ? Number(data.PLANT_C3H8_PRDT_RATE_H * 1000).toFixed(0)
            : '--',
          butaneComparison: data.PLANT_C4H10_PRDT_RATE_H
            ? Number(data.PLANT_C4H10_PRDT_RATE_H * 1000).toFixed(0)
            : '--',
          olefinComparison: data.PLANT_C5P_PRDT_RATE_H
            ? Number(data.PLANT_C5P_PRDT_RATE_H * 1000).toFixed(0)
            : '--'
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  getAll() {
    this.getYieldTarget()
    this.getAllChart()
    this.getProductionTarget()
    this.getProductionCost()
    this.getDeviceStatus()
    this.getProductionTotal()
    this.getEnergyTarget()
    this.getEnergyTotal()
    this.getProductionTargetBefore()
  }
  componentDidMount() {
    // setScreen(1920, 1080, 'screen')
    this.getAll()
    setInterval(() => {
      this.setState({
        currentTime: moment().format('YYYY-MM-DD HH:mm:ss')
      })
    }, 1000)
  }

  outScreen() {
    const self = this
    confirm({
      title: '确认退出大屏?',
      content: '',
      onOk() {
        console.log('退出大屏')
        self.props.history.goBack()
      },
      onCancel() {}
    })
  }
  render() {
    const {
      ethaneEfficiency,
      propaneEfficiency,
      butaneEfficiency,
      ethaneEfficiencyChart,
      propaneEfficiencyChart,
      butaneEfficiencyChart,
      feedGasChart,
      naturalGasChart,
      ethaneChart,
      propaneChart,
      butaneChart,
      olefinChart,
      electricConsumeChart,
      naturalGasConsumeChart,
      waterConsumeChart,
      nitrogenConsumeChart,
      feedGasNumber,
      feedGasPercent,
      feedGasComparison,
      naturalGasNumber,
      naturalGasPercent,
      naturalGasComparison,
      ethaneNumber,
      ethanePercent,
      ethaneComparison,
      propaneNumber,
      propanePercent,
      propaneComparison,
      butaneNumber,
      butanePercent,
      butaneComparison,
      olefinNumber,
      olefinPercent,
      olefinComparison,
      productionCostObj,
      deviceStatusObj,
      gasProductWeek,
      gasProductMonth,
      gasProductYear,
      ethaneProductWeek,
      ethaneProductMonth,
      ethaneProductYear,
      propaneProductWeek,
      propaneProductMonth,
      propaneProductYear,
      butaneProductWeek,
      butaneProductMonth,
      butaneProductYear,
      olefinProductWeek,
      olefinProductMonth,
      olefinProductYear,
      feedGasTotalWeek,
      feedGasTotalMonth,
      feedGasTotalYear,
      electricTotalWeek,
      electricTotalMonth,
      electricTotalYear,
      waterTotalWeek,
      waterTotalMonth,
      waterTotalYear,
      nitrogenTotalWeek,
      nitrogenTotalMonth,
      nitrogenTotalYear,
      electricConsumeNumber,
      naturalGasConsumeNumber,
      waterConsumeNumber,
      nitrogenConsumeNumber,
      currentTime
    } = this.state
    return (
      <div className="newScreen" id="screen">
        <div className="newScreen-header">
          <div className="newScreen-header-left">PEIMS</div>
          <div className="newScreen-header-right">
            <span>{currentTime}</span>
            <div
              style={{ display: 'inline-block', cursor: 'pointer' }}
              onClick={this.outScreen.bind(this)}
            >
              <img src={outImg} width="20" height="16" />
              <span>退出大屏</span>
            </div>
          </div>
        </div>
        <div className="newScreen-content">
          <div className="newScreen-content-left">
            <div className="newScreen-content-left-title">主要指标</div>
            <YieldTarget
              ethaneEfficiency={ethaneEfficiency}
              propaneEfficiency={propaneEfficiency}
              butaneEfficiency={butaneEfficiency}
              ethaneEfficiencyChart={ethaneEfficiencyChart}
              propaneEfficiencyChart={propaneEfficiencyChart}
              butaneEfficiencyChart={butaneEfficiencyChart}
            />
            <ProductionTarget
              feedGasChart={feedGasChart}
              naturalGasChart={naturalGasChart}
              ethaneChart={ethaneChart}
              propaneChart={propaneChart}
              butaneChart={butaneChart}
              olefinChart={olefinChart}
              feedGasNumber={feedGasNumber}
              feedGasPercent={feedGasPercent}
              feedGasComparison={feedGasComparison}
              naturalGasNumber={naturalGasNumber}
              naturalGasPercent={naturalGasPercent}
              naturalGasComparison={naturalGasComparison}
              ethaneNumber={ethaneNumber}
              ethanePercent={ethanePercent}
              ethaneComparison={ethaneComparison}
              propaneNumber={propaneNumber}
              propanePercent={propanePercent}
              propaneComparison={propaneComparison}
              butaneNumber={butaneNumber}
              butanePercent={butanePercent}
              butaneComparison={butaneComparison}
              olefinNumber={olefinNumber}
              olefinPercent={olefinPercent}
              olefinComparison={olefinComparison}
            />
            <EnergyTarget
              electricConsumeChart={electricConsumeChart}
              naturalGasConsumeChart={naturalGasConsumeChart}
              waterConsumeChart={waterConsumeChart}
              nitrogenConsumeChart={nitrogenConsumeChart}
              electricConsumeNumber={electricConsumeNumber}
              naturalGasConsumeNumber={naturalGasConsumeNumber}
              waterConsumeNumber={waterConsumeNumber}
              nitrogenConsumeNumber={nitrogenConsumeNumber}
            />
          </div>
          <div className="newScreen-content-center">
            <Overview />
            <div style={{ display: 'flex' }}>
              <ProductionTotal
                gasProductWeek={gasProductWeek}
                gasProductMonth={gasProductMonth}
                gasProductYear={gasProductYear}
                ethaneProductWeek={ethaneProductWeek}
                ethaneProductMonth={ethaneProductMonth}
                ethaneProductYear={ethaneProductYear}
                propaneProductWeek={propaneProductWeek}
                propaneProductMonth={propaneProductMonth}
                propaneProductYear={propaneProductYear}
                butaneProductWeek={butaneProductWeek}
                butaneProductMonth={butaneProductMonth}
                butaneProductYear={butaneProductYear}
                olefinProductWeek={olefinProductWeek}
                olefinProductMonth={olefinProductMonth}
                olefinProductYear={olefinProductYear}
              />
              <EnergyTotal
                feedGasTotalWeek={feedGasTotalWeek}
                feedGasTotalMonth={feedGasTotalMonth}
                feedGasTotalYear={feedGasTotalYear}
                electricTotalWeek={electricTotalWeek}
                electricTotalMonth={electricTotalMonth}
                electricTotalYear={electricTotalYear}
                waterTotalWeek={waterTotalWeek}
                waterTotalMonth={waterTotalMonth}
                waterTotalYear={waterTotalYear}
                nitrogenTotalWeek={nitrogenTotalWeek}
                nitrogenTotalMonth={nitrogenTotalMonth}
                nitrogenTotalYear={nitrogenTotalYear}
              />
            </div>
          </div>
          <div className="newScreen-content-right">
            <ProductionCost productionCostObj={productionCostObj} />
            <DeviceStatus deviceStatusObj={deviceStatusObj} />
          </div>
        </div>
      </div>
    )
  }
}
export default NewScreen
