import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import echarts from 'echarts';
import CommonContext from '../../context';
import { post } from 'common/utils';
import ICON_LOAD from '../InfoCard/res/load.png'

const initChart = (refNode, value) => {
    if (!!refNode) {
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        instance.setOption({
            // tooltip: {
            //     trigger: 'item',
            //     formatter: '{a} <br/>{b}: {c} ({d}%)'
            // },
            series: [
                {
                    name: '负载率',
                    type: 'pie',
                    radius: ['78%', '100%'],
                    hoverAnimation: false,
                    label: {
                        show: false
                    },
                    labelLine: {
                        show: false
                    },
                    data: typeof value === "number" ? [
                        { value: value, name: '', itemStyle: { color: '#FEBC05' } },
                        { value: 1 - value, name: '', itemStyle: { color: '#E9E9E9' } },
                    ] : [
                            { value: 0, name: '', itemStyle: { color: '#E9E9E9' } },
                        ],
                }
            ]
        });
    }
};

const Comp = (props) => {
    const chartNode = useRef(null);
    const [value, setValue] = useState(null);
    const commonInfo = useContext(CommonContext);
    const [selectedDevice, refreshTick] = useMemo(() => {
        if (!!commonInfo) {
            return [commonInfo.state.selectedDevice, commonInfo.state.refreshTick];
        };
        return [null, null];
    }, [commonInfo]);
    const queryLock = useRef(null);
    useEffect(() => {
        if (!!selectedDevice) {
            const tag_name = selectedDevice.tag_name + '_LDRT';
            const currLock = (queryLock.current = Symbol());
            post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time_x', {
                tag_name_list: [tag_name],
            })
                .then((retData) => {
                    if (queryLock.current !== currLock) {
                        return;
                    };
                    // console.log(retData);
                    if (!!retData.data && retData.data.errcode === 0) {
                        const tmpValue = retData.data.results[tag_name];
                        let nextValue = null;
                        if (typeof tmpValue === 'number' && tmpValue >= 0 && tmpValue <= 1) {
                            nextValue = tmpValue;
                        };
                        setValue(nextValue);
                        initChart(chartNode.current, nextValue);
                    };
                });
            return () => {
                queryLock.current = Symbol();
            };
        };
    }, [selectedDevice, refreshTick]);

    const valueText = useMemo(() => {
        if (typeof value === 'number') {
            return Math.floor(value * 10000) / 100;
        } else {
            return '--';
        };
    }, [value]);
    return (
        <div className={style['wrapper']}>
            <div className="title">
                {/*<img src={ICON_LOAD} />*/}
                负载率
            </div>
            <div className="chart">
                <div className="chart_item" ref={chartNode}></div>
                <div className="value_wrapper">
                    <div className="value">{valueText}</div>
                    <div className="unit">%</div>
                </div>
            </div>
        </div>
    );
};

export default Comp;
